body {
    background-color: #1b1d28;
    margin: 0;
    line-height: inherit;
    font-style: normal;
}

.home-page {
    width: 100%;
    height: 100%;
}

.main-container {
    display: grid;
    height: fit-content;
    gap: 1rem;
}

@media (min-width: 768px) {
    .main-container {
        gap: 3rem;
    }
}

.tabs {
    display: grid;
    gap: 1.5rem; /* gap-6 */
    padding-left: 0.5rem; /* px-2 */
    padding-right: 0.5rem; /* px-2 */
}

@media (min-width: 768px) {
    /* md breakpoint */
    .tabs {
        gap: 1rem; /* md:gap-4 */
        padding-left: 3rem; /* md:px-12 */
        padding-right: 3rem; /* md:px-12 */
    }
}

.tab-header {
    display: grid;
    gap: 0.5rem; /* gap-2 */
    padding-left: 0.5rem; /* px-2 */
    padding-right: 0.5rem; /* px-2 */
}

.tab-control .ant-tabs-tab {
    color: #6b7280;
    font-size: 1rem; /* text-sm */
    font-weight: bold; /* font-bold */
}

.tab-control .ant-tabs-tab:hover {
    color: #6b7280;
}

.tab-control .ant-tabs-tab-active {
    color: #6ee7b7;
}

.tab-control .ant-tabs-ink-bar-animated {
    height: 4px !important;
}

.tab-control .ant-tabs-nav::before {
    display: none;
}

.tab-options {
    display: grid;
    gap: 1rem; /* gap-4 */
    width: 100%; /* w-full */
    align-items: center; /* items-center */
    font-size: .875rem;
    line-height: 1.25rem;
}

/* For small screens and up (min-width: 640px) */
@media (min-width: 640px) {
    .tab-options {
        display: flex; /* sm:flex */
    }
}

.tab-options .ant-radio-button-wrapper {
    background-color: transparent;
    border: none;
    color: #6b7280;
}

.tab-options .ant-radio-button-wrapper::before {
    display: none !important;
}

.tab-options .ant-radio-button-wrapper-checked {
    background-color: #6ee7b7;
    color: black;
    border-radius: .25rem;
}

.tab-options span {
    font-weight: bold; /* font-bold */
}

.fun-content {
    display: grid;
    grid-template-columns: repeat(1, 1fr); /* grid-cols-1 */
    gap: 1rem; /* gap-4 */
    color: #9ca3af; /* text-gray-400 */
    padding-left: 0.5rem; /* px-2 */
    padding-right: 0.5rem; /* px-2 */
}

/* For medium screens and up (min-width: 768px) */
@media (min-width: 768px) {
    .fun-content {
        grid-template-columns: repeat(2, 1fr); /* md:grid-cols-2 */
        padding-left: 3rem; /* md:px-12 */
        padding-right: 3rem; /* md:px-12 */
    }
}

/* For large screens and up (min-width: 1024px) */
@media (min-width: 1024px) {
    .fun-content {
        grid-template-columns: repeat(3, 1fr); /* lg:grid-cols-3 */
        padding-left: 3rem; /* md:px-12 */
        padding-right: 3rem; /* md:px-12 */
    }
}

.fun-content .ant-skeleton-avatar-circle {
    background-color: #334155 !important;
}

.fun-content .ant-skeleton-title {
    background-color: #334155 !important;
}

.fun-content .ant-skeleton-paragraph > li {
    background-color: #334155 !important;
}

.pagination {
    width: 100%; /* w-full */
    display: flex; /* flex */
    justify-content: center; /* justify-center */
    margin-top: 1rem; /* mt-4 */
    margin-bottom: 5rem;
}

/* Shake animation */
@keyframes shake {
    0% {
        transform: translateX(0);
        background-color: #ff0
    }

    10% {
        transform: translateX(-25px);
        background-color: #ff0
    }

    20% {
        transform: translateX(25px);
        background-color: #ff0
    }

    30% {
        transform: translateX(-25px)
    }

    40% {
        transform: translateX(25px)
    }

    50% {
        transform: translateX(-25px)
    }

    60% {
        transform: translateX(25px)
    }

    70% {
        transform: translateX(-25px)
    }

    80% {
        transform: translateX(25px)
    }

    90% {
        transform: translateX(-25px)
    }

    to {
        transform: translateX(0)
    }
}

.fun-content > .fun-item-container:first-child {
    animation: shake 0.6s ease-in-out;
}
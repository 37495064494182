
.search-container {
    display: grid; /* grid */
    padding-left: 0.5rem; /* px-2 (8px) */
    padding-right: 0.5rem; /* px-2 (8px) */
    width: 95vw; /* w-[95vw] */
    justify-content: center; /* justify-center */
}

@media (min-width: 640px) { /* sm: for small screens and larger */
    .search-container {
        padding-left: 0; /* sm:p-0 */
        padding-right: 0; /* sm:p-0 */
    }
}

.inside-search {
    display: flex;               /* Sets the display to grid */
    gap: 0.5rem;                /* Sets the gap between grid items (adjust based on your framework's scale) */
    width: 90vw;                /* Sets the width to 90% of the viewport width */
    max-width: 450px;           /* Sets the maximum width to 450 pixels */
    justify-content: space-between;
}

.btn-search {
    background-color: #6ee7b7; /* bg-green-300 */
    color: #000000; /* text-black */
    padding: 0.5rem; /* p-2 */
    border-radius: 0.25rem; /* rounded */
    transition: background-color 0.3s ease; /* For smooth hover transition */
    border: none;
}

.btn-search:hover {
    background-color: #34d399 !important;
    color: black !important;
}
.header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: .5rem;
    align-items: flex-start;
    height: fit-content;
}

.first-items {
    gap: .5rem;
    display: flex;
    align-items: center;
}

.first-items > img {
    max-width: 100%;
    height: auto;
    color: transparent;
    margin-right: 1rem;
}

.btn-link {
    display: grid;
    height: fit-content;
}

.btn-link > .btn-link-group {
    display: flex;
    gap: .5rem;
}

.btn-link > .btn-link-group > a {
    color: white;
    text-decoration: none;
    font-weight: normal;
}

.btn-link > .btn-link-group > a:hover {
    text-decoration: underline;
    font-weight: bold;
}

.btn-link > .btn-link-group > button {
    color: white;
    text-decoration: none;
    font-weight: normal;
    background-color: transparent;
    background-image: none;
    border: none;
    cursor: pointer;
    font-size: inherit;
}

.btn-link > .btn-link-group > button:hover {
    font-weight: bold;
    background-color: transparent;
}

.second-items {
    gap: .5rem;
    display: flex;
}

@media (max-width: 1200px) {
    .second-items .bumping:nth-of-type(2) {
        display: none; /* Ẩn phần tử thứ hai khi chiều rộng màn hình nhỏ hơn 1200px */
    }
}

.bumping {
    padding: 0.5rem; /* p-2 (0.5rem = 8px) */
    border-radius: 0.25rem; /* rounded */
    display: flex; /* flex */
    align-items: center; /* items-center */
    gap: 0.25rem; /* gap-1 (0.25rem = 4px) */
    font-size: 0.875rem; /* text-sm (14px) */
    background-color: #fca5a5; /* bg-red-300 (color code for red-300) */
    animation: shake 0.6s ease-in-out;
    /*animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both; !* animate-shake *!*/
}

/* Shake animation */
@keyframes shake {
    0% {
        transform: translateX(0);
        background-color: #ff0
    }

    10% {
        transform: translateX(-25px);
        background-color: #ff0
    }

    20% {
        transform: translateX(25px);
        background-color: #ff0
    }

    30% {
        transform: translateX(-25px)
    }

    40% {
        transform: translateX(25px)
    }

    50% {
        transform: translateX(-25px)
    }

    60% {
        transform: translateX(25px)
    }

    70% {
        transform: translateX(-25px)
    }

    80% {
        transform: translateX(25px)
    }

    90% {
        transform: translateX(-25px)
    }

    to {
        transform: translateX(0)
    }
}

.third-items {
    gap: .5rem;
    display: flex;
}

.third-items > .connect-wallet {
    display: grid;
    justify-items: end;
}

.third-items > .connect-wallet > button {
    color: white;
    text-decoration: none;
    font-weight: normal;
    background-color: transparent;
    background-image: none;
    border: none;
    cursor: pointer;
    font-size: inherit;
}

.third-items > .connect-wallet > button:hover {
    font-weight: bold;
    background-color: transparent;
}
.footer {
    margin-top: auto; /* mt-auto */
    padding-top: 1rem; /* py-4 */
    padding-bottom: 1rem; /* py-4 */
    color: #9ca3af; /* text-gray-400 */
    font-size: 0.875rem; /* text-sm */
    padding-left: 0.5rem; /* px-2 */
    padding-right: 0.5rem; /* px-2 */
}

/* For medium screens and up (min-width: 768px) */
@media (min-width: 768px) {
    .footer {
        padding-left: 3rem; /* md:px-12 */
        padding-right: 3rem; /* md:px-12 */
    }
}

.inside-footer {
    display: flex; /* flex */
    justify-content: space-between; /* justify-between */
    align-items: center; /* items-center */
    position: relative; /* relative */
}

.inside-footer .google {
    color: #9ca3af; /* text-gray-400 */
    font-size: 0.75rem; /* text-xs */
    position: absolute; /* absolute */
    left: 50%; /* left-1/2 */
    transform: translateX(-50%); /* transform -translate-x-1/2 */
    text-align: center; /* text-center */
}

.inside-footer .google a {
    color: #9ca3af;
}
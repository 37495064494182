.cover {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 1rem;
}

.cover > a {
    display: inline-flex; /* inline-flex */
    align-items: center; /* items-center */
    justify-content: center; /* justify-center */
    white-space: nowrap; /* whitespace-nowrap */
    border-radius: 0.375rem; /* rounded-md */
    font-weight: 500; /* font-medium */
    transition: color 0.2s, background-color 0.2s; /* transition-colors */
    outline: none; /* focus-visible:outline-none */
    height: 2.5rem; /* h-10 */
    padding: 0.5rem 1rem; /* px-4 py-2 */
    margin-bottom: 1rem; /* mb-4 */
    font-size: 1.5rem; /* text-2xl */
    color: #f8fafc; /* text-slate-50 */
    background-color: transparent; /* Default background */
    position: relative;
    cursor: pointer;
}

/* Focus ring styles */
.cover > a:focus-visible {
    box-shadow: 0 0 0 2px #0f172a, 0 0 0 4px #f8fafc; /* focus-visible:ring-2 focus-visible:ring-slate-950 focus-visible:ring-offset-2 */
}

/* Disabled state */
.cover > a:disabled {
    pointer-events: none; /* disabled:pointer-events-none */
    opacity: 0.5; /* disabled:opacity-50 */
}

/* Dark mode styles */
@media (prefers-color-scheme: dark) {
    .cover > a {
        box-shadow: 0 0 0 4px #0f172a; /* dark:ring-offset-slate-950 */
    }

    .cover > a:focus-visible {
        box-shadow: 0 0 0 2px #f8fafc, 0 0 0 4px #1e293b; /* dark:focus-visible:ring-slate-300 */
    }

    .cover > a:hover {
        background-color: #1e293b; /* dark:hover:bg-slate-800 */
        color: #f8fafc; /* dark:hover:text-slate-50 */
    }
}

/* Hover styles */
.cover > a:hover {
    font-weight: bold; /* hover:font-bold */
    background-color: transparent; /* hover:bg-transparent */
    color: #f8fafc; /* hover:text-slate-50 */
}

.inner-cover {
    color: #ffffff; /* text-white */
    max-width: 800px; /* max-w-[800px] */
    display: grid; /* grid */
    gap: 0.5rem; /* gap-2 (0.5rem = 8px) */
}

.inner-cover > img {
    height: 2rem; /* h-8 (2rem = 32px) */
    justify-self: center; /* justify-self-center */
    background: transparent;
}

.fun-item-container {
    padding: 0.5rem; /* p-2 */
    display: flex; /* flex */
    border: 1px solid transparent; /* border */
    gap: 0.5rem; /* gap-2 (8px) */
    width: 100%; /* w-full */
    max-height: 300px; /* max-h-[300px] */
    overflow: hidden; /* overflow-hidden */
    border-color: transparent; /* border-transparent */
}

.fun-item-container:hover {
    border-color: white; /* hover:border-white */
}

.image-container {
    /*min-width: 5rem; !* min-w-20 *!*/
}

.image {
    margin-right: 1rem; /* mr-4 */
    width: 8rem; /* w-20 */
    height: auto; /* h-auto */
    display: block;
    color: transparent;
}

.content {
    display: grid; /* grid */
    gap: 0.25rem; /* gap-1 (4px) */
    height: fit-content; /* h-fit */
}

.creator-info {
    display: flex; /* flex */
    align-items: center; /* items-center */
    gap: 0.5rem; /* gap-2 */
    font-size: 0.75rem; /* text-xs */
    color: #bfdbfe; /* text-blue-200 */
}

.creator-button {
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
}

.creator-details {
    display: flex; /* flex */
    align-items: center; /* items-center */
    gap: 0.25rem; /* gap-1 */
}

.creator-image {
    width: 1rem; /* w-4 */
    height: 1rem; /* h-4 */
    border-radius: 0.25rem; /* rounded */
    display: block;
    color: transparent;
}

.creator-name {
    padding-left: 0.25rem; /* px-1 */
    padding-right: 0.25rem; /* px-1 */
    border-radius: 0.25rem; /* rounded */
    background-color: transparent; /* hover:bg-transparent */
    color: #f8fafc; /* hover:text-slate-50 */
}

.creator-name:hover {
    text-decoration: underline; /* hover:underline */
}

.market-info {
    display: flex; /* flex */
    gap: 0.25rem; /* gap-1 */
    font-size: 0.75rem; /* text-xs */
    color: #d1fae5; /* text-green-300 */
    align-items: center;
}

.badge-info {
    display: flex;
    color: #22c55e; /* text-green-500 */
}

.badge-info > img {
    width: 16px;
    height: 16px
}

.badge-button {
    cursor: pointer;
    background: none;
    border: none;
    padding: 0;
}

.badge-image {
    width: 1.125rem; /* w-4.5 */
    height: 1.125rem; /* h-4.5 */
}

.replies-info {
    display: flex; /* flex */
    align-items: center; /* items-center */
    gap: 0.5rem; /* gap-2 */
    font-size: 0.75rem; /* text-xs */
    margin: 0;
}

.fun-item-title {
    font-size: 1rem; /* text-sm */
    width: 100%; /* w-full */
    margin: 0;
}
